<template>
    <v-carousel
        v-if="items.length"
        interval="6000"
        :cycle="multipleItems"
        :show-arrows="multipleItems"
        :show-arrows-on-hover="multipleItems"
        hide-delimiters
        hide-delimiter-background
        height="200"
    >
        <v-carousel-item v-for="item of items" :key="item.id" class="px-0">
            <component v-bind:is="typeMapping[item.type]" :item="item" />
        </v-carousel-item>
    </v-carousel>
</template>

<script>
export default {
    components: {
        MessageItem: () => import('./items/MessageItem'),
    },
    data() {
        return {
            typeMapping: {
                message: 'MessageItem',
            },
        }
    },
    computed: {
        items() {
            const items = this.$store.getters['spotlight/getItems']
            return items.filter(
                (item) => item.enabled && !this.isExpired(item.expiry)
            )
        },
        multipleItems() {
            return this.items.length > 1
        },
    },
    methods: {
        isExpired(date) {
            if (!date) {
                return false
            }
            const expiry = new Date(date).valueOf()
            const now = new Date().valueOf()
            return expiry < now
        },
    },
}
</script>

<style></style>
